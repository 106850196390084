<template>
  <div>
    <Login
      v-if="loaded"
      :global-error-message="loginErrorMessage"
      :icon-u-r-l="$appConfig.customization.login.logoLogin"
      :display-register="$appConfig.customization.login.enableRegistration"
      :register-offer="$appConfig.customization.login.registrationOffer"
      @login="login"
      @forgotPassword="forgotPassword"
    >
      {{ this.$appConfig.customization.login.name }}
    </Login>
    <v-container
      v-else
      fill-height
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col align="center">
          <div class="img-background-app"></div>
          <v-row>
            <v-col>
              <SpinnerBeelse
                :multiple="true"
                color="orange"
                :size="200"
                logo="B"
                :display-logo="false"
              >
              </SpinnerBeelse>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="text-h4 mt-12">
                {{ $t('Loading') }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>



<script>
import {
  EventBus,
  Login,
  ApiErrorParser,
  SpinnerBeelse
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageLogin.i18n');

export default {
  name: 'PageLogin',
  components: {
    Login,
    SpinnerBeelse
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loaded: false,
      loginErrorMessage: '',
    };
  },
  created() {
    EventBus.$on('globalLoginProcessComplete', () => {
      this.checkLogin();
    });

    EventBus.$on('reloadBrand', () => {
      this.getUserBrands();
    });

    EventBus.$on(
      'globalOrganizationSelectorOrganizationChanged',
      (brandUUID) => {
        /**
         * Redirect and reload to rebuild the App
         */
        // this.$router.push({name: 'Dashboard', params: { brandUUID: `${brandUUID}`}});
        this.$appConfig.currentBrand = brandUUID;
        this.loaded = false;
        this.getUserBrands();
      }
    );

    this.checkLogin();
  },
  methods: {
    checkLogin() {
      // console.log('PageLogin.vue:checkLogin()');
      const userUUID = this.$getCookie('_pu');
      const userPassword = this.$getCookie('_pp');
      if (!userUUID || !userPassword) {
        this.deleteCookies();
      } else {
        /**
         * Cookies found, verify Authentication
         */
        this.$apiInstance.apiClient.authentications[
          'JWTAuth'
        ].accessToken = userPassword;
        this.$apiInstance.getUser(userUUID).then(
          (data) => {
            this.$appConfig.user = data;
            /**
             * GET USER BRAND INFORMATION
             */
            this.getUserBrands();
          },
          (error) => {
            this.loginErrorMessage = ApiErrorParser.parseAuth(error);
            this.deleteCookies();
          }
        );
      };
    },
    getUserBrands() {
      // console.log('PageLogin.vue:getUserBrands()');
      this.$apiInstance.getUserBrands(this.$appConfig.user._id).then(
        (userBrandsdata) => {
          /**
           * Cleanup brands which are not related to this Theme Domains
           */
          const filteredBrandData = [];
          userBrandsdata.forEach(brand => {
            if(brand.features.theme === this.$appConfig.customization.login._id) {
              filteredBrandData.push(brand);
            }
          });

          /**
           * Save filtered brand for the current domain
           */
          this.$appConfig.userBrands = filteredBrandData;
          
          /**
           * Check and add login global error message
           * if the user doesn't have access to this portal
           */
          if (this.$appConfig.userBrands.length === 0) {
            this.loginErrorMessage = this.$t('NoAccess');
            this.deleteCookies();
          } else {
            /**
             * If no brandUUID already exist in URL params,
             * Check if there is a redirect matching a specific brandUUID
             * and check if the current user have access to this brand
             * If not, select the first one.
             */
            let brandUUID = null;
            if(this.$appConfig.redirectAfterLogin) {
              const nextRoute = this.$router.resolve(this.$appConfig.redirectAfterLogin);
              if(nextRoute?.route?.params?.brandUUID) {
                brandUUID = nextRoute.route.params.brandUUID;
              }
            }
            if (!brandUUID) {
              /**
               * If currentBrand already exists (set by organization selector events)
               * Otherwise, use the first brand in the list
               */
              if(!this.$appConfig.currentBrand) {
                this.$appConfig.currentBrand = this.$appConfig.userBrands[0]._id;
              }
            } else {
              this.$appConfig.currentBrand = brandUUID;
            }
            /**
             * Check if the user has access to the current brandUUID param
             * This method will also check the access from brand manager (in case of startup managed warehouse)
             */
            this.$apiInstance
              .getBrandUserRoles(
                this.$appConfig.currentBrand,
                this.$appConfig.user._id
              )
              .then((brandUserRolesData) => {
                this.$appConfig.brandUserRoles = brandUserRolesData;
                if (brandUserRolesData.length > 0) {
                  this.$appConfig.isAuthenticated = true;
                  /**
                   * If there is a redirection go to the saved URL
                   */
                  if(this.$appConfig.redirectAfterLogin) {
                    const nextRoute = this.$router.resolve(this.$appConfig.redirectAfterLogin);
                    // console.log(`PageLogin.vue:getUserBrands() - Redirect to ${this.$appConfig.redirectAfterLogin}`);
                    if(nextRoute?.route && !['Login', 'Logout', 'Register', 'RegisterValidate', '404'].includes(nextRoute?.route?.name)) {
                      this.$router.push(nextRoute.route);
                    }else{
                      this.$router.push('/');
                    }
                    /**
                     * Cleanup redirect
                     */
                    this.$appConfig.redirectAfterLogin = null;
                  }else{
                    // console.log('PageLogin.vue:getUserBrands() - Redirect to /');
                    this.$router.push('/');
                  }
                } else {
                  this.deleteCookies();
                }
              })
              .catch((err) => {
                this.deleteCookies();
              });
          }
        },
        (error) => {
          this.loginErrorMessage = ApiErrorParser.parseAuth(error);
          this.deleteCookies();
         
        }
      );
    },
    login(email, password) {
      this.loginErrorMessage = '';
      const authBody = new this.$BcmModel.AuthBody(email, password);
      this.$apiInstance.auth(authBody).then(
        (data) => {
          const date = new Date();
          date.setHours(date.getHours() + 8);
          const dateString = date.toGMTString();
          this.$setCookie('_pu', data._id, dateString);
          this.$setCookie('_pp', data.token, dateString);
          /**
           * Login Success : CheckLogin
           */
          this.checkLogin();

        },
        (error) => {
          if(error?.error?.message?.includes('Request has been terminated')) {
            this.loginErrorMessage = this.$t('loginFailedTemporary');
            this.init();
          }else{
            this.loginErrorMessage = ApiErrorParser.parseAuth(error);
            this.init();
          }
        }
      );
    },
    forgotPassword(email) {
      this.loginErrorMessage = '';
      const opts = {
        'themeUUID': this.$appConfig.customization.login._id // String | The UUID of the theme
      };
      const emailRequestBody = new this.$BcmModel.SendNewUserPasswordBody(email);
      this.$apiInstance
        .sendNewUserPassword(emailRequestBody, opts)
        .then(() => {
          /**
           * Forgot password Success
           */
        })
        .catch((err) => {
          this.loginErrorMessage = ApiErrorParser.parseAuth(error);
          this.init();
        });
    },
    deleteCookies() {
      this.$deleteCookie('_pu');
      this.$deleteCookie('_pp');
      this.loaded = true;
    },
  }
};
</script>

<style scoped>

  .img-background-app {
    background: url(https://www.beelse.com/wp-content/themes/theme-beelse/assets/img/hive.svg);
    background-size: 130% auto;
    position: absolute;
    right: 0px;
    bottom: 0px;
    opacity: 0.3;
    height: 95%;
    width: 95%;
  }

</style>
