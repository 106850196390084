var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loaded ? _c('Login', {
    attrs: {
      "global-error-message": _vm.loginErrorMessage,
      "icon-u-r-l": _vm.$appConfig.customization.login.logoLogin,
      "display-register": _vm.$appConfig.customization.login.enableRegistration,
      "register-offer": _vm.$appConfig.customization.login.registrationOffer
    },
    on: {
      "login": _vm.login,
      "forgotPassword": _vm.forgotPassword
    }
  }, [_vm._v(" " + _vm._s(this.$appConfig.customization.login.name) + " ")]) : _c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "img-background-app"
  }), _c('v-row', [_c('v-col', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": true,
      "color": "orange",
      "size": 200,
      "logo": "B",
      "display-logo": false
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "text-h4 mt-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('Loading')) + " ")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }